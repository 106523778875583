#subnav {
  padding: 0;
  min-height: 0;
  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 0;
    li {
      display: inline-block;
      a {
        display: block;
        height: 80px;
        line-height: 80px;
        padding: 0 20px;
        color: $gray-400;
        font-family: "ITCBookmanW01-LightItal", serif;
        &:hover {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}