@media print {
  #topnav,
  #mobilenav,
  #mainnav,
  #page-header,
  #page-footer,
  #contact-form,
  #awards,
  .button,
  form {
    display: none !important;
  }
  a[href]:after {
     content:"" !important;
  }
  #page-content {
    margin-top: 0 !important;
  }
  .hero {
    height: auto !important;

  }
  .container {
    padding: 20px 0 !important;
  }
  .print-only {
    display: block;
  }
  .hide-on-print {
    display: none;
  }
}

.print-only {
  display: none;
}