.nonprofit-icons {
  font-family:'ITC Bookman W01 Light', 'ITCBookmanW01-Light', Georgia, Cambria, "Times New Roman", Times, serif;
  font-style: italic;
  font-weight: bold;
}

.nonprofit-list {
  li {
    margin-bottom: 10px;

    ul {
      margin-top: 10px;
    }
  }

}