h1, h2, h3, h4, h5, h6 {
  color: $primary;
  font-family: 'ITCBookmanW01-LightItal', serif;
  line-height: 1.1em;
  margin-bottom: 0.6em;
}

h1 { 
  font-size: 40px;
  @include media-breakpoint-down(sm) {
    font-size: 25px;
  }
} 
h2 { 
  font-size: 35px;
  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
}
h3 { 
  font-size: 25px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}
h4 { font-size: 24px;}
h5 { font-size: 18px; color: $dark;}
h6 { font-size: 1rem;}

p {
  margin-bottom: 1.5em;
}