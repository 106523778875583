input[type="text"],
input[type="password"],
input[type="email"],
input[type="textarea"],
textarea,
select,
.select {
  border-radius: 0;
  background-color: #EFEFF0;
  border-style: solid;
  border-width: 0px;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.75);
  display: block;
  font-family: 'ITCBookmanW01-LightItal',serif;
  font-size: 0.875rem;
  line-height: 43px;
  height: 43px;
  text-indent: 10px;
}

.select {
  position: relative;
  .arrow-down {
    position: absolute;
    right: 11px;
    top: 20px;
  }
  select {
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;       /* remove default arrow */
    line-height: 1;
    height: 43px;
    width: 100%;
    text-indent: 10px;
    padding-left: 10px;
  }
}

fieldset {
  padding: 10px;
}

.subfield {
  display: block;
  input[type="checkbox"] {
    margin-bottom: 0;
    margin-right: 3px;
  }
  input[type="checkbox"]+label {
    font-family: 'ITCBookmanW01-LightItal',serif;
    font-size: 12px;
    margin: 0;
  }
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 3px solid transparent;
  border-right: 3px solid transparent; 
  border-top: 3px solid rgba(0,0,0,0.75);
}

#services {
  position: relative;
  border-radius: 0;
  background-color: #EFEFF0;
  border-style: solid;
  border-width: 0px;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.75);
  display: block;
  font-family: 'ITCBookmanW01-LightItal', serif;
  font-size: 0.875rem;
  line-height: 43px;
  height: 43px;
  text-indent: 17px;
  .arrow-down {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

#service-choices {
  display: none;
  position: absolute;
  top: 43px;
  left: 15px;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  margin: 0;
  &.active {
    display: block;
  }
}


.el-button {
  color: $white !important;
  border-radius: 0 !important;
  padding: 12px 23px !important;
  background-color: $primary !important;
  border-color: $primary !important;
  border: 0 !important;
  outline: 0 !important;
  width: 100%;
}

.cog-page__navigation {
  padding-top: 14px !important;
}
