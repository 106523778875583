.btn,
.button {
  color: $white;
  border-radius: 0;
  padding: 12px 23px;
  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
  &.btn-yellow {
    background-color: #F0A733;
    border-color: #F0A733;
  }
}

.el-button {
  color: $white !important;
  border-radius: 0 !important;
  padding: 12px 23px !important;
  background-color: $primary !important;
  border-color: $primary !important;
  border: 0 !important;
  outline: 0 !important;
  width: 100%;
}

.cog-page__navigation {
  padding-top: 12px !important;
}
