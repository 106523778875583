.benefits-section {
  h2 {
    &.bg-gray {
      margin-top: 40px;
      margin-bottom: 30px;
      padding: 25px;
    }
  }
  h3 {
    margin-bottom: 10px;
  }
  h4 {
    font-size: 19px;
    margin-bottom: 30px;
  }
  h6 {
    color: $gray-400;
  }
  img {
    width: 60px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

  .card {
    .card-header {
      h3 {
        margin: 0;
      }
    }
    .card-body {
      height: 370px;
    }
  }
  @media (max-width: 767px) {
    .flex-grid {
      flex-wrap: wrap;
      justify-content: flex-start;
      .flex-item {
        width: 33%;
        text-align: center;
      }
    }
  }
  @media (max-width: 51em) {
    .card {
      .card-body {
        height: auto;
      }
    }
  }
}