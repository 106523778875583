#search {
  background: url(images/testimonial-background.jpg) no-repeat top center;
  h2 {
    color: $white;
    padding-bottom: 20px;
  }
  input {
    padding: 3px;
    text-indent: 5px;
  }
  .button {
    background: $gray-400;
    margin-left: 20px;
    font-size: 20px;
  }
}

body.search_results {
  #page-content {
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    overflow: hidden;
  }
  #site-search-results {
    opacity: 1;
    pointer-events: all;
  }
}

#site-search-results {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 500;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  @include transition();
  ul {
    list-style-type: none;
    margin-left: 0;
  }
}


#site-search-form {
  display: flex;
  align-items: center;
  height: 50px;
  label {
    display: none;
    color: #f2f2f2;
    font-size: 1rem;
    line-height: 1;
    padding: 0 15px 0 35px;
    font-family: $font-family-base;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 200;
    
  }
  input {
    // display: none;
    margin: 0;
    padding: 0 15px;
    height: 24px;
    font-family: $font-family-base;
    color: #fff;
    background:transparent;
    background-color: transparent;
    border:none;
    border-radius: 0;
    border-style: none;
    border-width: 0;
    border-color: none;
    box-shadow: none;
    display: inline-block;
    line-height: 1;
    font-size: 11px;
  }
  input[type="text"] {
    width: 150px;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: $gray-300;
    position: relative;
    text-indent: 20px;
  }
  input[type="submit"] {
    background: $primary;
    margin-left: 0;
  }
}

#close-search {
  position: absolute;
  right: 20px;
  top: 10px;
  font-weight: 100;
  font-size: 25px;
  z-index: 500;
  cursor: pointer;
}

.search {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 3px;
    top: 14px;
    background: url(/img/icons/icon-search.png) no-repeat center center;
  }
}