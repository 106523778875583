@font-face {
  font-family: 'higroup';
  src:  url('/fonts/higroup.eot?oqwxno');
  src:  url('/fonts/higroup.eot?oqwxno#iefix') format('embedded-opentype'),
    url('/fonts/higroup.woff2?oqwxno') format('woff2'),
    url('/fonts/higroup.ttf?oqwxno') format('truetype'),
    url('/fonts/higroup.woff?oqwxno') format('woff'),
    url('/fonts/higroup.svg?oqwxno#higroup') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'higroup' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-check:before {
  content: "\e917";
  color: #81ba3f;
}
.icon-irs-audit:before {
  content: "\e918";
  color: #65656a;
}
.icon-tax-planning:before {
  content: "\e919";
  color: #65656a;
}
.icon-tax-preparation:before {
  content: "\e91a";
  color: #65656a;
}
.icon-tax-problems:before {
  content: "\e91b";
  color: #65656a;
}
.icon-higroup:before {
  content: "\e900";
}

.icon-accounting, .icon-caaccount, .icon-healthcare {
  display: inline-block;
  width: 42px;
  height: 42px;
  margin: auto;
  margin-right: 12px;
}

.icon-accounting:before {
  content: url(../img/icons/icon-hiaccounting.svg);
}
.icon-caaccount:before {
  content: url(../img/icons/icon-californiaaccounting.svg);
}
.icon-healthcare:before {
  content: url(../img/icons/icon-hihealthcare.svg);;
}
.icon-social-google:before {
  content: "\e904";
}
.icon-social-twitter:before {
  content: "\e905";
}
.icon-social-linkedin:before {
  content: "\e906";
}
.icon-social-instagram:before {
  content: "\e907";
}
.icon-social-facebook:before {
  content: "\e908";
}
.icon-checkbox:before {
  content: "\e909";
}
.icon-cloud:before {
  content: "\e90a";
}
.icon-email:before {
  content: "\e90b";
}
.icon-equalize:before {
  content: "\e90c";
}
.icon-exit:before {
  content: "\e90d";
}
.icon-gears:before {
  content: "\e90e";
}
.icon-menu:before {
  content: "\e90f";
}
.icon-money:before {
  content: "\e910";
}
.icon-notepad:before {
  content: "\e911";
}
.icon-payroll:before {
  content: "\e912";
}
.icon-security:before {
  content: "\e913";
}
.icon-support-accounting:before {
  content: "\e914";
}
.icon-support-general:before {
  content: "\e915";
}
.icon-support-payroll:before {
  content: "\e916";
}
