#page-content {
  position: relative;
  margin-top: 75px;
  @include transition();
  overflow: hidden;
}
.logged-in {
  #page-content {
    margin-top: 17px;
  }
}