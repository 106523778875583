/* -----------------------------------------
   Global Styles
----------------------------------------- */
html {
}
body {
  margin: 0;
  color: $body-color;
}
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
a {
  color: $primary;
  &:hover,
  &:focus {
    color: $link-hover;
    text-decoration: none;
    cursor: pointer;
    outline: none;
  }
}
h4 {
  color: $body-color;
}
dl {
  dt {
    font-family: $header-font-family;
  }
  dd {
    margin-left: 65px;
  }
}
hr {
  max-width: 62.5rem;
  margin: 1.25rem auto 1.1875rem;
}

em {
  color: $primary;
}
i {
  font-size: 45px;
  margin-right: 15px;
  vertical-align: middle;
}
select {
  font-family: $header-font-family;
  color: rgba(0, 0, 0, 0.35);
  line-height: 38px;
  height: 53px;
  text-indent: 10px;
}

.button, .el-button {
  background-color: $primary;
  &:hover,
  &:active,
  &:focus {
    background-color: $link-hover;
  }
}

ul {
  padding-left: 20px;
}

.offset {
  &:before {
    display: block;
    content: " ";
    margin-top: -200px;
    height: 200px;
    visibility: hidden;
  }
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

section {
  padding: 25px 0;
  @include media-breakpoint-up(md) {
    padding: 70px 0;
  }
}

/* -----------------------------------------
   DOM Specific Styles
----------------------------------------- */
#page {
}


#footernav {
  a {
    padding: 0 10px;
    font-size: 14px;
    text-transform: uppercase;
    color: $gray-200;
    font-family: $font-family-base;
  }
}

#carousel-wrapper {
  i {
    text-align: center;
    display: block;
    font-size: 75px;
    padding: 50px 0;
  }
}

#hero-carousel {
  font-family: $header-font-family;
  h2 {
    color: $white;
  }
  p {
    color: $white;
  }
  .hero-wrap {
    position: relative;
    .hero-content {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      padding: 30px 0;
    }
  }
  .button {
    display: inline-block;
  }
  &.carousel-mobile {
    background: $gray-800;
    h2 {
      color: $primary;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      margin-bottom: 15px;
    }
    .slick-dots {
      display: none !important;
    }
  }
}




