#testimonials {
  background: url(/img/global/testimonial-background.jpg) no-repeat top center;
  font-family: $header-font-family;
  text-align: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  em {
    font-size: 19px;
    color: $white;
    font-style: normal;
  }
  p {
    color: $white;
    line-height: 1.2;
  }
  div.row {
    margin: auto;
  }
}

.testimonial-list {
  h4 {
    font-size: 20px;
  }
}