#benefits {
  h2 {
    margin: 20px 0;
  }
  img {
    margin: 10px 0;
  }
}

#staff-member {
  i {
    color: $gray-400;
    font-size: 25px;
  }
}

.greend {
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: $primary;
  color: #ffffff;
}

#perks {
  li {
    clear: both;
    height:50px;
    list-style-type:none;
    margin-bottom:20px;

    img {
      width: 32px;
      height: auto;
      margin: 0 20px 20px 10px;
      display: block;
      float: left;
    }
  }

}

.contact-meta {
  font-size: 14px;
  line-height: 18px;
  padding: 0;
  margin: 0;
  padding-right: 10px;
  color:#646569;
  display: inline-block;
  height:18px;

  

  &.green {
    color: #8dc63f;
  }

}

.member-meta {
  padding-bottom:30px;

  img {
    margin: 0;
    padding: 0;
    margin-top:-2px;
    padding-right: 4px;

  }

}