#awards {
  img {
    width: 100%;
    height: 190px;
    &.height-auto {
      height: auto;
    }
    &.large {
      height: 250px;
    }
  }
}

@media (max-width: 767px) {
  #awards {
    img {
      &.height-auto {
        max-width: 230px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}


@media (max-width: 500px) {
  #awards {
    img {
      height: auto;
      max-width: 200px;
      &.height-auto {
        max-width: 150px;
        display: block;
        margin: 0 auto;
      }
      &.large {
        height: auto;
      }
    }
  }
}