.flex-grid {
  display: flex;
  &.align-items-center {
    align-items: center;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
}