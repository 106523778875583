$img-w: 3000px;
$img-h: 245px;
$shrink: 1;
$ratio: 1 / $shrink;
$speed: 80s;

.slider {
  // position: absolute;
  width: 100%;
  // top: calc(50% - #{$img-h * $ratio / 2});
  overflow: hidden;

  .slider-row {
    width: $img-w * $ratio;
    height: $img-h * $ratio;
    background-image: url('/img/HiAccounting-Team-Carousel-2022-R1.jpg');
    background-size: $img-w * $ratio $img-h * $ratio;
    animation: slide $speed linear infinite;
  }
}

@keyframes slide {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: -$img-w * $ratio;
  }
}