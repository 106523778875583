@mixin transition($duration: .3s, $property: all, $effect: ease-in-out) {
  transition: $property $duration $effect;
}
@mixin background-image-retina($path, $file, $type) {
  background-image: url($path + $file + '.' + $type);
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx){
    & {
      background-image: url($path + $file + '@2x.' + $type);
    }
  }
}

@mixin middle-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}