#notification-bar {
  p {
    font-size: 13px;
  }
  .content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

a.top-icon {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}

#grp-icon {
  background-image:url(../img/icons/icon-hawaiigroup-grey.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

#grp-icon:hover {
  background-image:url(../img/icons/icon-hawaiigroup.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

#ca-icon {
  background-image:url(../img/icons/icon-californiaaccounting-grey.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

#ca-icon:hover {
  background-image:url(../img/icons/icon-californiaaccounting.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

#hlt-icon {
  background-image:url(../img/icons/icon-hihealthcare-grey.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

#hlt-icon:hover {
  background-image:url(../img/icons/icon-hihealthcare.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.navbar-brand {
  width: 250px;
  @include media-breakpoint-down(sm) {
    width: 165px;
  }
}

// #page-header {
//   position: fixed;
//   background: #4b4948;
//   z-index: 99999;
//   width: 100%;
//   top: 0;
//   left: 0;
//   &.open_subnav {
//     #mainnav {
//       // box-shadow: 0 3px 15px rgba(0, 0, 0, 0.5);
//     }
//     #subnav {
//       display: block;
//       height: auto;
//       top: 114px;
//     }
//   }
  
// }

#page-header {
  position: fixed;
  z-index: 99999;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.5);
  background-color: $white;
  &.open_subnav {
    #mainnav {
      // box-shadow: 0 3px 15px rgba($black, 0.5);
    }
    #subnav {
      display: block;
      height: auto;
      top: 114px;
    }
  }
  @include media-breakpoint-down(md) {
    .container {
      max-width: 100%;
    }
  }
  h3 {
    color: $white;
    overflow: visible;
    font-family: $font-family-base;
    font-size: 13px;
    font-weight: 300;
    white-space: nowrap;
    a {
      border: none;
      line-height: 1;
      height: auto;
      padding: 0;
      background-color: $gray-800;
      color: $white;
    }
    li {
      a {
        color: $white;
      }
    }
  }
  ul {
    margin-left: 0;
    list-style: none;
    padding-left: 0;
    li {
      margin: 0;
      font-weight: 200;
      a {
        color: $white;
        line-height: 1.2em;
        background: transparent;
        border: none;
        font-size: 13px;
        padding: 3px 0;
        white-space: normal;
        text-transform: none;
      }
    }
  }
  em {
    color: #80a43f;
  }
  .border {
    min-height: 200px;
  }
  .client-vault {
    position: relative;
    span {
      position: absolute;
      top: 6px;
      left: -117px;
      display: block;
      background-color: #8dc63f;
      width: 119px;
      height: auto;
      z-index: 1000;
      font-size: 11px;
      padding: 4px;
      color: #fff;
      opacity: 0;
      line-height: 1.4;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        left: 117px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid $primary;
      }
    }
  }
}

#iconnav {
  @include media-breakpoint-down(md) {
    text-align: left;
  }

  a {
    height: 38px;
    display: inline-block;
    background-color: transparent;
    padding-top: 6px;
    margin-right: 12px;
    
    svg {
      height: 26px;
      width: auto;
      margin: auto;
      display: block;

      .st0 {
        fill: $gray-200;
      }

    }

  &:hover {
    svg {
      .st0 {
        fill: $primary;
      }
    }

    &.alt {
      svg {
        .st0 {
          fill: $yellow;
        }
      }
    }
  } 
  }
}
#topnav {
  background: #646469;
  line-height: 50px;
  // TODO - Add back in the search bar feature now that things are cleaned up
  ul {
    margin: 0;
    li {
      margin: 0;
      border-top: 5px solid transparent;
      background: transparent;
      &.client-vault {
        border-top: 0;
      }
      &.search {
        border-top: 0;
      }
      &.active {
        border-color: #80a43f;
      }
      &.brand {
        width: 125px;
        text-indent: -9999em;
        background: #484849 url(images/logo-myhihr.png) top center no-repeat;
        a {
          border: none;
        }
        #logo_dropdown {
          width: 220px;
          max-width: none;
          margin-top: 13px;
          background: #484849;
          border: none;
          padding: 10px 0;
          &:before,
          &:after {
            display: none;
          }
          li {
            padding: 5px;
            a {
              color: #ffffff;
              text-indent: 0;
              text-align: right;
            }
          }
        }
      }
      a {
        color: #e8e8e8;
        padding: 0 15px;
        font-family: $font-family-base;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 200;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
#mainnav {
  height: 72px;
  font-family: $font-family-base;
  padding-top: 0;
  padding-bottom: 0;

  .row .row {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 62.5rem;
  }
}

#primarynav {
  background: transparent;
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  padding: 0;

  ul {
    li {
      &.megamenu-toggle {
        &:hover {
          > .megamenu {
            opacity: 1;
            height: auto;
            visibility: visible;
          }
        }
      }
      a {
        font-size: 13px;
        border-top: 2px solid transparent;
        border-bottom: 0;
        color: $gray-800;
        text-transform: uppercase;
        padding: 0 1.1375rem;
        height: 72px;
        line-height: 72px;
      }
      > a {
        &:hover,
        &.active {
          color: $white;
          background: #4b4948;
          border-color: $green;
        }
      }
    }
    .megamenu {
      position: fixed;
      width: 100%;
      top: 110px;
      left: 0;
      padding: 30px 0;
      background: $gray-800;
      box-shadow: 0 5px 5px rgba($black, 0.5);
      opacity: 0;
      height: 0;
      visibility: hidden;
      @include transition(opacity, 0.3s);
      a {
        color: $white;
        line-height: 1.2;
        height: auto;
        text-transform: none;
        font-style: italic;
        padding: 0;
        &:hover {
          border-color: $gray-800;
        }
      }
      h3 {
        a {
          font-size: 13px;
          text-transform: uppercase;
          font-style: normal;
        }
      }
    }
  }
}

#mobile {
  position: fixed;
  top: 36;
  left: 0;
  width: 100%;
  padding: 0;
  background-color: $white;
  ul {
    li {
      background-color: $gray-800;
      font-size: 13px;
      padding-left: 20px;
      padding-right: 20px;
      .dropdown-menu {
        height: auto;
        overflow: hidden;
        font-family: $font-family-base;
        background: transparent;
        z-index: -1;
        border: 0;
        padding: 10px;
        a {
          border-bottom: 0;
        }
        ul {
          li {
            padding-left: 10px;
            padding-right: 10px;
            a,
            .dropdown-item {
              border-bottom: 0 !important;
              font-style: italic;
            }
          }
        }
      }
      a {
        height: auto;
        line-height: 1.25;
        font-family: $font-family-base;
        font-size: 13px;
        font-weight: 200;
        border-bottom: 1px solid $gray-300;
        padding: 12px 0;
        color: $white;
      }
    }
  }

  .navbar-brand {
    padding-left: 20px;
  }
  .navbar-toggler {
    padding-right: 20px;
  }
}

#mobilenav {
  background-color: $gray-800;
}

.navbar-toggler {
  padding: 0;
  z-index: 1000;
  position: relative;
  &:focus {
    outline: none;
  }
  &.collapsed {
    .navbar-toggler-icon {
      .icon-bar {
        &:nth-of-type(1) {
          top: 10px;
          transform: rotate(0);
        }
        &:nth-of-type(2) {
          top: 16px;
          transform: rotate(0);
        }
        &:nth-of-type(3) {
          top: 22px;
          opacity: 1;
        }
      }
    }
  }

  .navbar-toggler-icon {
    position: relative;
    height: 36px;
    width: 40px;
    padding: 10px;
    background-color: transparent;
    overflow: hidden;
    .icon-bar {
      width: 18px;
      height: 2px;
      background-color: $gray-800;
      position: absolute;
      left: 10px;
      z-index: 1000;
      @include transition(0.5s);
      &:nth-of-type(1) {
        top: 16px;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        top: 16px;
        transform: rotate(-45deg);
      }
      &:nth-of-type(3) {
        top: 12px;
        opacity: 0;
      }
    }
  }
}

#iconnav {
  text-align: center;
  a {
    display: inline-block;
    background-color: transparent;
  }
}
#topnav {
  background: #646469;
  line-height: 38px;
  // TODO - Add back in the search bar feature now that things are cleaned up

  ul {
    margin: 0;
    overflow: visible;
    li {
      margin: 0;
      border-top: 5px solid transparent;
      background: transparent;
      &.search {
        border-top: 0;
      }
      &.active {
        border-color: #80a43f;
      }
      &.brand {
        width: 125px;
        text-indent: -9999em;
        background: #484849 url(images/logo-myhihr.png) top center no-repeat;
        a {
          border: none;
        }
        #logo_dropdown {
          width: 220px;
          max-width: none;
          margin-top: 13px;
          background: #484849;
          border: none;
          padding: 10px 0;
          &:before,
          &:after {
            display: none;
          }
          li {
            padding: 5px;
            a {
              color: #ffffff;
              text-indent: 0;
              text-align: right;
            }
          }
        }
      }
      a {
        color: #e8e8e8;
        padding: 0 15px;
        font-family: $font-family-base;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 200;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}
#mobilenav {
  min-height: 50px;
  h1 {
    position: relative;
    display: block;
    padding-left: 30px;
    margin-bottom: 0;
    height: 50px;
    a {
      text-indent: -9999em;
      display: block;
      width: 150px;
      line-height: 50px;
      background: transparent url(/img/logo.svg) no-repeat center center;
      background-size: contain;
    }
  }
  .client-vault-mobile {
    .button {
      display: inline-block;
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      background-color: $gray-400;
    }
  }
  &.top-bar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    // .myhihr-button {
    //   text-indent: -9999px;
    //   background: $gray-400 url(/img/logo.svg) no-repeat center center;
    //   border-bottom: 0;
    //   &:after {
    //     display: none;
    //   }
    // }
    .name {
      height: 50px;
    }
    .menu {
      background-color: $gray-800;
    }
    .toggle-topbar {
      a {
        //background: transparent url(images/menu.svg) no-repeat center center;
        // background-size: 30px;
        cursor: pointer;
        i {
          color: $gray-400;
          font-size: 27px;
        }
      }
    }
    &.expanded {
      .toggle-topbar {
        a {
          background: url(/img/gobal/exit.svg) no-repeat center center;
        }
      }
    }
  }
  .top-bar-section {
    ul {
      li {
        padding: 0 20px;
        &.myhihr {
          width: 49.5%;
          display: inline-block;
          padding-left: 20px;
          padding-right: 0;
          border-bottom: none;
        }
        &.mobile-search {
          display: inline-block;
          width: 49.5%;
          background: url(/img/global/icon-search.png) no-repeat left 15% center;
          margin-left: -4px;
          padding-left: 0;
          text-align: center;
          a {
            &:hover {
              background: transparent;
            }
          }
        }
        &:last-child {
          a {
            border-bottom: none;
          }
        }
        a {
          border-bottom: 1px solid $gray-300;
          padding: 12px 0;
        }
      }
    }
    .dropdown {
      li {
        a {
          padding: 12px 0;
        }
      }
    }
    .has-dropdown {
      a {
        &:after {
          right: 25px;
        }
      }
    }
    .parent-link {
      display: none;
    }
  }
}
