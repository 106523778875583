#page-footer {
  width: 100%;
  background: $gray-400;
  font-size: 12px;
  color: $gray-200;
  overflow: hidden;
  padding-bottom: 30px;
  padding-top: 70px;
  hr {
    border-color: #999;
  }
  .social {
    margin-top: 10px;
    min-width: 160px;
    a {
      color: $gray-200;
      &:hover {
        color: $green-bright;
      }
      i {
        font-size: 25px;
      }
    }
  }
  #copyright {
    p {
      font-size: 13px;
      margin-bottom: 0;
      padding-top: 12px;
    }
  }
  
}

#footersub {
  box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.6);
  background-color: $gray-800;
  padding-top: 20px;
  padding-bottom: 20px;
  ul {
    li {
      padding: 0 10px;
      a {
        line-height: 40px;
      }
    }
  }
  svg {
    display: block;
    height: 30px;
  }
}

@media (max-width: 500px) {
  #page-footer {
    .social {
      margin-bottom: 10px;
    }
    #copyright {
      p {
        padding-bottom: 12px;
      }
    }
  }
  #footersub {
    padding: 15px;
    box-shadow: none;
    svg {
      display: block;
      margin: 0;
    }
    ul {
      li {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
