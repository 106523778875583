#plan-2020 {
  .content {
    max-width: 900px;
  }
}

#resource-grid {
  .btn {
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
  }
}


.resource-body {
  img {
    width: 100%;
    height: auto;
  }
}