.posts {
  display: none;
  .post {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-100;
    .content {
      max-width: 100%;
    }
    .post-featured-image {
      padding: 20px;
      img {
        width: 250px;
        max-width: none;
        
      }
    }
  }
  .close-post {
    position: absolute;
    right: 20px;
    top: -20px;
    opacity: 0;
    font-size: 13px;
    @include transition(.5s);
    pointer-events: none;
    .close {
      padding: 3px 5px;
      background-color: $primary;
      color: #fff;
      margin-left: 10px;
    }
  }
  .post-link {
    cursor: pointer;
    height: auto;
    opacity: 1;
    @include transition(.5s);
  }
  .post-pdf, .post-content {
    height: 0;
    opacity: 0;
    pointer-events: none;
    display: block;
    @include transition(.5s);

    h5 {
      margin-bottom: 20px;
    }
    ol, ul {
      margin-left: 25px;
      li {
        padding: 20px 0;
      }
      ul {
        margin-left: 50px;
        li {
          padding: 5px 0;
        }
        ul {
          margin-left: 50px;
          li {
            padding: 0;
          }
        }
      }
    }
    ul {
      list-style-type: disc;
      &.section-list {
        list-style-type: none;
        li {
          &:before {
            content: "§";
            padding-right: 5px;
          }
        }
      }
    }
    .sub-section {
      padding: 20px;
      background-color: $gray-100;
      margin: 20px 0;
      ul {
        margin-left: 50px;
      }
      ul, ol {
        li {
          padding: 5px 0;
        }
      }
    }
  }
  .post-excerpt {
    height: auto;
    opacity: 1;
    @include transition(.5s);
  }
  &.posts-loaded {
    display: block;
    &.single-post-view {
      position: relative;
      .close-post {
        opacity: 1;
        cursor: pointer;
        pointer-events: all;
      }
      .post {
        display: none;
        &.active {
          display: block;
          .post-featured-image {
            img {
              width: auto;
              max-width: 640px;
            }
          }
          .post-pdf, .post-content {
            height: auto;
            opacity: 1;
            pointer-events: auto;
          }
          .post-pdf {
            margin: 20px 0;
          }
          .post-excerpt {
            height: 0;
            opacity: 0;
            pointer-events: none;
          }
          .post-read {
            opacity: 0;
            height: 0;
            pointer-events: none;
          }
        }
      }
    }
  }
}

//styles specific to 2018 tax post
#individual, #business {
  margin-top: 50px;
}


@media(max-width: 50em) {
  .posts {
    .post {
      flex-wrap: wrap;
      .post-featured-image {
        display: none;
        padding: 20px;
        img {
          width: 100%;
          max-width: none;
        }
      }
    }
  }
}