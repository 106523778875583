#home-hero {
  .hero-caption {
    text-align: left;
    h1 {
      font-size: 40px;
      line-height: 1;
      margin-bottom: 20px;
      min-height: 0;
      height: auto;
    }
  }
}

#carouselExampleIndicators {
  .hero-caption {
    text-align: left;
    h1 {
      font-size: 40px;
      line-height: 1;
      margin-bottom: 20px;
      min-height: 0;
      height: auto;
    }
  }

  .carousel-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}


// new styles for announcement and video modal on homepage
#announcement {
  h3 { 
    color: $gray-400;
  }
  img {
    margin-bottom: 20px;
  }
  a {
    font-family: 'ITCBookmanW01-LightItal', serif;
  }
  p {
    margin-bottom: 0;
  }
  .content {
    padding: 30px 20px;
  }
}

#play-commercial {
  img {
    margin: 30px 0;
    max-width: 300px;
  }
}

#videoModal {
  .close-reveal-modal {
    top: 0;
    right: 10px;
  }
}

// end new styles

#accounting-you-can-trust {
  i {
    font-size: 75px;
    display: block;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 23px;
  }
}

#content-support {
  background-color: $gray-100;
  background: url(/img/backgrounds/bg-lighthouse-large.jpg) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
  p {
    color: $white;
  }
  .support-logo {
    margin: 30% 0px;
  }
  .hero {
    margin-bottom: 20px;
  }
}

#testimonials {
  background: url(/img/global/testimonial-background.jpg) no-repeat center center;
  font-family: $header-font-family;
  text-align: center;
  background-size: cover;
  em {
    font-size: 26px;
    color: $white;
    font-style: normal;
  }
  p {
    color: $white;
    line-height: 1.2;
  }
}

#locations {
  font-family: $header-font-family;
  font-size: 13px;
  h1 {
    font-size: 1rem;
  }
  .content {
    padding: 0 0 0 25px;
  }
}

ul#support-list {
  text-align: left;
  li { 
    padding: 20px;
    margin-bottom: 20px;
    &:last-child {
      h2 {
        border-bottom: 0;
      }
    }
  }

  a.caoverride {
    background-color: #F0A733;
    border-color: #F0A733;
  }

  h2 {
    font-size: 22px !important;
    margin-bottom: 0 !important;
  }

  h1, h2 {
    color: $gray-800;
    border-bottom: 1px solid $gray-200;
    padding-bottom: 10px;
    font-family: $font-family-serif;
    i {
      color: $primary;
    }
  }
  .modal {
    color: $gray-400;
    .close {
      text-align: right;
    }
    .modal-content {
      padding: 20px;
      text-align: left;
    }
      h1, h2, p {
      color: $gray-400;
      font-family: $header-font-family;
    }
  }
}